<template>
  <v-container
    class="justify-center d-flex fill-height align-center"
    style="max-width: 400px"
    fluid>
    <v-dialog
      v-model="validation"
      width="500"
      >
      <v-card>
        <v-card-title
          class="justify-center"
          >
          Valida tu correo
        </v-card-title>

        <v-card-text>
          Verifica tu casilla de correo para validar que la cuenta te pertenece. <span class="font-weight-medium">No te olvides de revisar la casilla de spam y promociones en caso de no encontrarlo</span>.
          <v-otp-input
            length="6"
            type="number"
            v-model="code"
            ></v-otp-input>

          <v-btn
            class="fill-width rounded-lg"
            large
            color="primary"
            :loading="loading"
            :disabled="loading"
            @click.stop="validateCode"
            >
            Validar
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card
      :loading="loading"
      :disabled="loading"
      rounded="lg"
      class="pa-8"
      :elevation="$vuetify.breakpoint.smAndDown ? 0 : 4"
      >
      <v-card-title 
        style="width:100%; word-break: break-word" class="pb-0 d-flex justify-center text-h4 font-weight-medium">
        <v-img
          :src="require('@/assets/title.png')"
          ></v-img>
      </v-card-title>
      <v-card-subtitle
        class="text-center mt-3 text-h6"
        >
        ¡Únete a nosotros! Tomará solo un instante.
      </v-card-subtitle>
      <v-card-text class="pb-0">
        <v-form
          v-model="valid"
          lazy-validation
          ref="form"
          >
          <v-alert 
            class="fill-width"
            outlined
            v-model="alert.alert"
            type="error"
            transition="scale-transition">
            {{`${$t('errors.' + alert.message)}`}}
          </v-alert>

          <first-step
            ref="firstStep"
            />
        </v-form>

        <v-btn
          @click.stop="validateEmail"
          large
          style="width:100%;"
          class="rounded-lg mb-3"
          color="primary">
          <span>
            {{ $t('actions.signup') }}
          </span>
        </v-btn>

        <v-btn
          @click.stop="back"
          large
          style="width:100%;"
          class="rounded-lg"
          >
          Regresar
        </v-btn>

        <v-divider
          v-if="tabs == 0"
          class="mt-4 mx-auto"
          inset
          ></v-divider>

        <v-row
          v-if="tabs == 0"
          >
          <v-col>
            <p class="text-center my-3">
              ¿Ya tienes cuenta?
              <router-link :to="{name: 'login'}">
                Iniciar sesión
              </router-link>
            </p>
          </v-col>
        </v-row>

        <terms
          class="text-center"
          disclaimer
          ></terms>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { CreateUser } from '@/graphql/mutations/users'
import { CreateCode, ValidateCode } from '@/graphql/mutations/validations'

const FirstStep = () => import('@/components/signup/FirstStep')
import Terms from '@/components/shared/Terms'

export default {
  name: 'Signup',
  data: () => ({
    alert: {
      alert: false,
      message: 'user_not_found',
    },
    valid: false,
    validation: false,
    code: null,
    loading: false,
    tab: 0,
    tabs: 0,
  }),

  components:{
    FirstStep, Terms
  },

  methods:{
    validateEmail(){
      this.loading = true;
      this.errors = {}
      
      this.valid = this.$refs.form.validate()

      if (!this.valid) {
        this.loading = false
        return
      }

      this.$apollo.mutate({
        mutation: CreateCode,
        variables: {
          input: {
            email: this.$refs.firstStep.signup.email
          }
        }
      }).then(res => {
        this.errors = {}
        this.loading = false

        this.validation = true
      }).catch(errors => {
        this.valid = true
        this.alert.alert = true
        this.alert.message = `${errors.graphQLErrors[0].extensions.field}_${errors.graphQLErrors[0].extensions.message}`
        this.loading = false
      })
    },

    validateCode () {
      this.loading = true;
      this.errors = {}
      
      this.valid = this.$refs.form.validate()

      if (!this.valid) {
        this.loading = false
        return
      }

      this.$apollo.mutate({
        mutation: ValidateCode,
        variables: {
          input: {
            email: this.$refs.firstStep.signup.email,
            code: this.code
          }
        }
      }).then(res => {
        this.errors = {}
        this.loading = false
        this.validation = false

        if (res.data.validateCode.success) {
          this.createUser()
        } else {
          this.valid = true
          this.alert.alert = true
          this.alert.message = "invalid_code"
        }
      }).catch(errors => {
        this.valid = true
        this.alert.alert = true
        this.alert.message = `${errors.graphQLErrors[0].extensions.field}_${errors.graphQLErrors[0].extensions.message}`
        this.loading = false

        this.validation = false
      })
    },

    createUser(){
      this.loading = true;
      this.errors = {}

      this.valid = this.$refs.form.validate()

      if (!this.valid) {
        this.loading = false
        return
      }

      this.$apollo.mutate({
        mutation: CreateUser,
        variables: {
          input: {
            attributes: this.$refs.firstStep.signup
          }
        }
      }).then(res => {
        this.errors = {}

        this.$store.commit("login");
        localStorage.setItem("auth_token", res.data.createUser.token);
        this.$router.go('')
      }).catch(errors => {
        this.valid = true
        this.alert.alert = true
        this.alert.message = `${errors.graphQLErrors[0].extensions.field}_${errors.graphQLErrors[0].extensions.message}`
        this.loading = false
      })
    },

    back () {
      if (this.tabs) {
        this.tabs -= 1
        this.userType = 'warehouse'
        this.$refs.thirdStep.option = 'warehouse'
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>
